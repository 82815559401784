.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; /* Fix 401 */
}

.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.noUi-handle {
    position: relative;
    z-index: 1;
}

.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
    z-index: 10;
}
.noUi-state-tap .noUi-origin {
    transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

.noUi-base {
    transform: translate3d(0,0,0);
}

.noUi-horizontal {
    height: 4px;
}

.noUi-horizontal .noUi-handle {
    width: 26px;
    height: 26px;
    left: -13px;
    top: -11px;
}

.noUi-horizontal {
    background: #E2E2E2;
    border-radius: 4px;
}

.noUi-handle {
    cursor: w-resize;
    border-radius: 50%;
    background: $c-brand;
    cursor: default;
    width: 20px;
    height: 20px;
    border: 5px solid transparent;
    background-clip: content-box;
}
