/* -------------------------------------------------------------------------- */

// Brand colours

$c-brand:                           #4d2f40 !default;
$c-secondary-brand:                 #a27550 !default;
$c-secondary-brand-block:           #d5c1b0 !default; // Lighter colour - use for bigger blocks/expanses eg buttons


/* -------------------------------------------------------------------------- */

// Typography based colours

$c-bodycopy:                        black !default;
$c-light-bodycopy:                  lighten($c-bodycopy, 50%) !default;


/* -------------------------------------------------------------------------- */

// Panels, borders and sectioning

$c-light-border:                    #aeaeae !default;
$c-border:                          #d4d4d4 !default;
$c-panel-bg:                        #f5f5f5 !default;
$c-modular-section-border:          #e1e1e1 !default;
$c-neutral-button:                  #888888 !default;


/* -------------------------------------------------------------------------- */

// UX colours

$c-positive:                        green !default;
$c-negative:                        #c62121 !default;
$c-required:                        $c-negative !default;
$c-offer:                           $c-negative !default;


/* -------------------------------------------------------------------------- */

// Forms and fields

$c-field-border:                    darken($c-border, 15%) !default;


/* -------------------------------------------------------------------------- */

// Tables

$c-zebra-bg:                        #fafafa !default;
$c-alt-zebra-bg:                    darken($c-zebra-bg, 5%) !default;


/* -------------------------------------------------------------------------- */

// Engraving

$c-engraving-grey:                  #e5e5e5 !default;
$c-engraving-border-colour:         #D4D4D4 !default;
