/* -------------------------------------------------------------------------- */

// Breakpoints

$breakpoints: (
    desktop: 								"min-width: 980px",
    tablet: 								"max-width: 979px",
    mobile: 								"max-width: 500px",

	// Nav specific
    large-desktop:                          "min-width: 1320px", /* Original non-Christmas value: 1200px */
    desktop-nav:                            "min-width: 1000px",  /* Original non-Christmas value:  920px */
    mobile-nav:                             "max-width: 1000px",   /* Original non-Christmas value:  920px */

    // Engraving specific
    engraving-desktop:                      "max-width: 801px"
);

$sizes: (
    l: false,
    m: tablet,
    s: mobile
);

/* -------------------------------------------------------------------------- */

// Site/frame settings

$absolute-min-site-width:                   250px; // Just cos
$absolute-max-site-width: 					1920px; // Outer content
$max-site-width: 							1300px; // Inner content
$site-gutter: 								5%; // Will always be at least this (think mobile)
$site-width:                                (100% - ($site-gutter * 2));


/* -------------------------------------------------------------------------- */

// Layout/Appearance settings

$section-padding: 							40px;
$compact-section-padding:                   ($section-padding / 2);


/* -------------------------------------------------------------------------- */

// Typography sizes

$base-px: 									16px; // Used for rem calculations only

$t-small-button:                            10px;
$t-button:									12px;

$t-navigation:                              14px;
$t-small-bodycopy:							12px;
$t-medium-bodycopy:                         14px;
$t-bodycopy:								16px;

$t-small-subtitle:                          12px;
$t-subtitle: 								14px;

$t-extra-small-title: 						16px;
$t-small-title: 							20px;
$t-title: 									24px;
$t-large-title: 							32px;
$t-extra-large-title: 						40px; // tbc

$t-standard-lineheight: 					1.5; // Bodycopy (smaller font sizes)
$t-tight-lineheight: 						1.3; // Titles etc (larger font sizes)
$t-spaced-lineheight:                       2;

$t-standard-tracking:						normal;
$t-spaced-tracking:							300;


/* -------------------------------------------------------------------------- */

// Grids

// Standard grid vars
$grid-gutter-h: 							30px;
$grid-gutter-v: 							30px;

// Product listing vars
$product-catalogue-gutter-x: 				50px; // left/right
$product-catalogue-gutter-y:                30px; // top/bottom

// Listing block vars
$listing-block-grid-gutter: 				5px;


/* -------------------------------------------------------------------------- */

// Typography fonts

// Used in webfont mixin
$webfont-title:                             "Caslon 540 LT W01 Roman", "Times New Roman", Times, serif;
$webfont-bodycopy:                          "Lato", "Myriad Pro", Helvetica, sans-serif;


/* -------------------------------------------------------------------------- */

// Forms

$input-font-size:                           $t-bodycopy !default;
$input-padding-y:                           0.5em !default; // Must be an em unit
$input-padding-x:                           0.75em !default; // Must be an em unit

$button-and-input-size-difference:          ($input-font-size - $t-button) !default;
$button-padding-y:                          (($input-padding-y / 1em) * $input-font-size) + ($button-and-input-size-difference / 2) !default;
$button-padding-x:                          2em !default;

/*
// Bit weird but makes button padding match inputs, select replace etc
// ($input-padding-y / 1em) == Strip units of em value
// * $input-font-size == calculate what the padding value (set in ems) would be in pixels
// + ($button-and-input-size-difference / 2) == Add on the difference between the two starting font sizes
*/

