@mixin breakpoint($point) {
  $responsive: true !default;
  @if $responsive {
    @if map-get($breakpoints, $point) {
      @media screen and (unquote(map-get($breakpoints, $point))) {
      @content;
    }
    } @else {
      @content;
    }
  } @else if null == index(map-keys($breakpoints), $point) {
    @content;
  }
}

@mixin rem($pixels) {
  font-size: $pixels;
  font-size: #{$pixels / $base-px}rem;
}

@mixin padding($positions) {
    @each $position in $positions {
        @include position(padding-#{$position});
    }
}

@mixin position($positions) {
    @each $position in $positions {
        #{$position}: $desktop-padding;
    }
    @include breakpoint(tablet) {
        @each $position in $positions {
            #{$position}: $tablet-padding;
        }
    }
    @include breakpoint(mobile) {
        @each $position in $positions {
            #{$position}: $mobile-padding;
        }
    }
}

@mixin triangle-up($background-color, $border-color, $triangle-width, $border-width ) {
  &:after, &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
  }
  &:after {
     border-bottom-color: $background-color;
     border-width: $triangle-width;
     margin-left: $triangle-width * -1;
   }
  &:before {
    border-bottom-color: $border-color;
    border-width: $triangle-width + ($border-width * 2);
    margin-left: ($triangle-width + ($border-width * 2)) * -1;
  }
}

@mixin triangle-down($background-color, $border-color, $triangle-width, $border-width ) {
  &:after, &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
  }
  &:after {
     border-top-color: $background-color;
     border-width: $triangle-width;
     margin-left: $triangle-width * -1;
   }
  &:before {
    border-top-color: $border-color;
    border-width: $triangle-width + ($border-width * 2);
    margin-left: ($triangle-width + ($border-width * 2)) * -1;
  }
}

// Because other files import this mixins file, just set to false for now
$ie8-support: false;

@mixin font-size-zero {
  font-size: 0;
  @if $ie8-support == true {
    #ie8 & {
      font-size: 0.1px;
    }
  }
}

// Can make this more complicated if needs be (if == 0 etc)
@mixin grid-gutter($gutter-x:$grid-gutter-h, $gutter-y:$grid-gutter-v) {
    margin-right: -($gutter-x);    
    > * {
        padding-right: $gutter-x; 
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
}

// Remove white space from space charecters in html etc 
@mixin remove-white-space {
    @include font-size-zero;
    letter-spacing: normal;
    word-spacing: normal;
    & > * {
        display: inline-block;
        @include rem($t-bodycopy);
    }
}


// Remove margin of last child
@mixin flush-last-child {
    & > :last-child {
        margin-bottom: 0;
    }
}


// Photoshop tracking to letter spacing
@mixin tracking($photoshop-value) {

    // Check if value is a number
    @if type-of($photoshop-value) == "number" {
        // If its 0 change to 'normal' which is UA default
        @if $photoshop-value == "0" {
            letter-spacing: normal;
        }
        // Otherwise divide by 1000 which is approx calculation
        @else {
            letter-spacing: #{($photoshop-value / 1000)}em;
        }
    }

    // Allow for keywords
    @else if $photoshop-value == "normal", "default", "none" {
        letter-spacing: normal;
    }
    
    // Warn if anything else and apply UA default
    @else {
        @warn "expected numerical value in tracking mixin";
        letter-spacing: normal;
    }  
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

// Box sizing
@mixin border-box() {
    box-sizing: border-box;
    * {
        &, &:before, &:after {
            box-sizing: inherit;
        }
    }
}

@mixin absolute-cover() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


@mixin max-width-wrap {
    // If you change this please update 'remove' mixin below 
    max-width: $max-site-width;
    margin-left: auto;
    margin-right: auto;
    width: $site-width;
}

@mixin remove-max-width-wrap {
    max-width: none;
    width: 100%;
}

@mixin clearfix {
    &:before,
    &:after {
        content: " "; 
        display: table; 
    }
    &:after {
        clear: both;
    }
    *zoom: 1;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v ----------------------------------------------------------------------------------

@mixin visually-hidden {
    border: 0; 
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

@mixin remove-visually-hidden {
    // Restore to UA defaults
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: static;
}

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Boostrap gradient mixins
// https://github.com/twbs/bootstrap-sass/blob/881ce27c6b4101698ea849283933203e20861a33/assets/stylesheets/bootstrap/mixins/_gradients.scss
// Updated for use on MV

// Horizontal gradient, from left to right
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal(

    $gradient-direction: to top, 
    $start-color: transparent, 
    $end-color: #000, 
    $start-percent: 0%, 
    $end-percent: 100%) {  

  background-image: linear-gradient($gradient-direction, $start-color $start-percent, $end-color $end-percent); 
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1);
  background-blend-mode: multiply;
}

// Vertical gradient, from top to bottom
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical(
    $gradient-direction: to bottom, 
    $start-color: transparent, 
    $end-color: #000, 
    $start-percent: 0%, 
    $end-percent: 100%) {

  background-image: linear-gradient($gradient-direction, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0);
  background-blend-mode: multiply;
}

@mixin gradient-vertical-three-colors(
    $start-color: transparent, 
    $mid-color: #000, 
    $color-stop: 50%, 
    $end-color: transparent) {

  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
  background-blend-mode: multiply;
}


@mixin gradient-radial(
  $inner-color: #000, 
  $outer-color: transparent) {

  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}

// Pagination wrap
$default-pagination-alignment: right;
@mixin pagination-wrap($pagination-alignment: $default-pagination-alignment) {    
    text-align: $pagination-alignment;
    padding: 10px 0;
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
    @include breakpoint(tablet) {
        text-align: center;
    }
}

@mixin clearfix {
    &:after {
        content: ".";
        display: block;
        height: 0;
        overflow: hidden;
        clear: both;
        visibility: hidden;
    }
}

@mixin center {
    margin-left: auto;
    margin-right: auto;
}