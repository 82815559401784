@import '../../../sass/settings/settings';

@import 'libs/no-ui-slider';
@import 'libs/flickity';
@import 'libs/keyboard';

.engraving-overlay {
    display: none;
    position: fixed;
    background: rgba(#000, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map-get($z-index, engraving-overlay);
}

.engraving-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: map-get($z-index, engraving-popover);
}

    .engraving-wrap--hidden {
        display: none;
    }

.engraving-container {
    position: relative;
    max-width: 930px;
    margin-bottom: 20px;
    margin: 20px auto;

    // Explicit media queries due to IE11 not playing ball
    @media screen and (max-width: 970px) {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 20px;
        margin: 0;
    }

    @include breakpoint(engraving-desktop) {
        position: relative;
        max-width: 450px;
        margin: 10px auto;
        left: auto;
        right: auto;
        top: auto;
    }

    @media screen and (max-width: 470px) {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        margin: 0;
    }
}

    .engraving-container--standalone {
        top: 0;
        left: 0;
        right: 0;
        margin-bottom: 0;
    }

    .engraving-container--hidden {
        display: none;
    }

.engraving-engraver {
    background: #fff;
    padding: 35px 30px;
    margin-bottom: 20px;

    @include breakpoint(engraving-desktop) {
        padding: 10px 0 0;
        margin-bottom: 10px;

        .engraving-container--standalone & {
            margin-bottom: 0;
            border-left: none;
            border-right: none;
        }
    }

    @media screen and (min-width: 460px) and (unquote(map-get($breakpoints, engraving-desktop))) {
        .engraving-container--standalone & {
            border-left: 1px solid $c-engraving-border-colour;
            border-right: 1px solid $c-engraving-border-colour;
        }
    }
}

    .engraving-engraver--standalone.engraving-engraver--editing {
        padding-top: 0;
    }

    .engraving-editor:not(.engraving-editor--selected) {
        display: none;
    }

    .engraving-engraver__inner-wrap {
        border: 1px solid $c-engraving-grey;
        margin-bottom: 35px;
        position: relative;

        @include breakpoint(engraving-desktop) {
            border: none;
            margin-bottom: 0;
        }
        .engraving-engraver--finished & {
            border-left: none;
            border-right: none;
            margin-bottom: 0;
        }
    }

    .engraving-engraver--editing {
        .engraving-engraver__inner-wrap {
            margin-bottom: 0;
        }
    }

    .engraving-engraver--finished {
        width: 450px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding: 10px 0 0;
        @include breakpoint(engraving-desktop) {
            width: auto;
        }
    }

.engraving-header {
    text-align: center;
    margin-bottom: 20px;

    @include breakpoint(engraving-desktop) {
        border-bottom: 1px solid $c-engraving-grey;
        margin-bottom: 0;
        padding: 5px 0 15px;
    }
    .engraving-engraver--finished & {
        margin: 5px 0 15px;
    }
}

    // Extra specificity to override title block
    .engraving-header .engraving-header__full {
        margin-bottom: 0;

        @include breakpoint(engraving-desktop) {
            display: none;
        }
    }

    .engraving-engraver--standalone.engraving-engraver--editing .engraving-header {
        display: none;
    }

    // Extra specificity to override title block
    .engraving-header .engraving-header__title {
        @include rem(22px);

        .engraving-engraver--finished & {
            @include rem(15px);
            margin: 0;
        }
        @include breakpoint(engraving-desktop) {
            @include rem(15px);
            margin: 0;
        }

        letter-spacing: 0.15em;
        text-transform: uppercase;
    }

    // Extra specificity to override title block
    .engraving-header .engraving-header__partial {
        display: none;
        margin-bottom: 0;

        @include breakpoint(engraving-desktop) {
            display: block;
        }
    }

    .engraving-header__sub-title {
        @include rem(14px);

        @media screen and (max-height: 750px) {
            display: none;
        }
    }

    .engraving-header__close-button {
        position: absolute;
        top: 15px;
        right: 20px;
    }

    .engraving-header__back-button {
        @include rem(32px);
        position: absolute;
        top: 0;
        left: 10px;

        .ui-icon {
            vertical-align: middle;
        }

        @media screen and (min-width: 800px) {
            left: 30px;
        }
    }

.engraving-component-header {
    text-align: center;
    position: absolute;
    text-transform: uppercase;
    line-height: 55px;
    border-bottom: 1px solid mix(#fff, $c-engraving-grey, 0.5);
    z-index: 1000;
    left: 0;
    right: 350px;
    background: #fff;

    @include breakpoint(engraving-desktop) {
        border-right: 1px solid mix(#fff, $c-engraving-grey, 0.5);
        position: relative;
    }
}

    .engraving-component-header__title {
        @include webfont(title);
        @include rem(9px);
        color: #7D7D7D;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 0;
        padding: 15px 0 7px;
    }

    .engraving-engraver .engraving-component-header__sub-title {
        @include webfont(title);
        @include rem(16px);
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 1;
        &--large {
            @extend .engraving-component-header__sub-title;
            margin-bottom: 23px;
            margin-top: 23px;
        }
    }

    .engraving-component-header__previous-link,
    .engraving-component-header__next-link {
        @include rem(32px);
        position: absolute;
        top: 0;
        padding: 0 20px;
        .ui-icon {
            vertical-align: middle;
        }
    }

    .engraving-component-header__previous-link {
        left: 0;
    }

    .engraving-component-header__next-link {
        right: 0;
    }

        .engraving-component-header__previous-link--disabled,
        .engraving-component-header__next-link--disabled {
            opacity: 0.25;
            cursor: default;
        }

.engraving-engraver .social-icon-list {
    display: inline-block;
    vertical-align: middle;

    .social-icon-list__item {
        margin: 0;
    }

    @include breakpoint(engraving-desktop) {
        display: none;
    }
}

.engraving-footer-save {
    display: inline-block;
    margin-left: 15px;

    *, .ui-icon {
        vertical-align: middle;
    }

    span {
        @include webfont(subtitle);
        @include rem(12px);
        letter-spacing: 0.15em;
        margin-left: 10px;
        display: inline-block;
    }

    @include breakpoint(engraving-desktop) {
        display: none;
    }
}

.engraving-footer-nav {
    float: right;

    @include breakpoint(engraving-desktop) {
        float: none;
    }
    .engraving-engraver--finished & {
        float: none;
    }
}

    // A bit of extra specificity for this to override a.button
    .engraving-footer-nav .engraving-footer-nav__item {
        margin-left: 10px;

        @include breakpoint(engraving-desktop) {
            display: block;
            margin: 15px;
        }
    }
    .engraving-engraver--finished .engraving-footer-nav .engraving-footer-nav__item  {
        display: block;
        margin: 15px;
    }

.engraving-editors {
    width: 100%;
    overflow: hidden;
    position: relative;
    outline: none;
}

.engraving-editor {
    @include clearfix;
    width: 100%;
    padding-right: 350px;
    background: #FFFFFF;
    position: relative;
    padding-top: 71px;

    @include breakpoint(engraving-desktop) {
        padding: 0;
    }
}

    .engraving-editor__preview {
        background-color: #fff;
        position: relative;
        background-size: contain;
        float: left;

        @include breakpoint(engraving-desktop) {
            border-bottom: 1px solid $c-engraving-grey;
            float: none;
        }
    }

    .engraving-editor__doodle-preview-wrap {
        position: relative;

        canvas {
            vertical-align: top;
        }
    }

    .engraving-editor__clear-doodle {
        position: absolute;
        bottom: -32px;
        right: 12px;
    }

    .engraving-editor-doodle-nav {
        background: #fff;
        border: 1px solid $c-engraving-grey;
        border-top: none;
    }

    .engraving-editor-doodle-nav__item {
        display: inline-block;
        padding: 2px;
        border-right: 1px solid $c-engraving-grey;
    }

        .engraving-editor-doodle-nav__item--selected {
            border: 2px solid $c-brand;
            padding: 0;
            padding-right: 1px;
        }

    .engraving-editor__uploaded-doodle-preview {
        border: 1px solid $c-engraving-grey;
        height: 200px;
        margin-bottom: 10px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: #fff;
    }

    .engraving-editor__drawing-area {
        border: 1px solid $c-engraving-grey;
        background-color: #fff;
        width: 100%;
    }

    .engraving-editor__canvas {
        display: block;
        width: 100%;
        max-width: 500px;
    }

    .engraving-editor__canvas--rotate-90 {
        transform: rotate(90deg);
    }

    .engraving-editor__canvas--rotate-270 {
        transform: rotate(270deg);
    }

    .engraving-editor__step {
        margin: -71px -350px 0 0;
        padding: 15px 15px 20px;
        width: 350px;
        float: right;
        background: #F7F7F7;
        border-left: 1px solid $c-engraving-grey;

        @include breakpoint(engraving-desktop) {
            float: none;
            margin: 0;
            position: relative;
            overflow: hidden;
            border-bottom: 1px solid $c-engraving-grey;
            border-left: none;
            width: 100%;

            .engraving-engraver--editing & {
                padding-bottom: 70px;
                border-bottom: none;
            }

            .engraving-engraver--editing .engraving-editor--is-engraved & {
                padding-bottom: 120px;
                border-bottom: none;
            }
        }
    }

    .engraving-editor__step--flush {
        padding: 0;

        .engraving-step-footer {
            padding: 0 15px 15px;
        }
    }

    .engraving-editor__next,
    .engraving-editor__previous {
        @include webfont(title);
        @include rem(10px);
        display: inline-block;
        padding: 20px 0 0;
    }

    .engraving-editor__previous {
        float: left;
    }

    .engraving-editor__next {
        float: right;
    }

    .engraving-editor__title {
        @include webfont(title);
        @include rem(13px);
        margin-bottom: 20px;
    }

.engraving-fonts {
    @include remove-white-space;
    margin-right: -13px;
}

.engraving-font {
    width: ((100% / 3) * 1);

    @include breakpoint(engraving-desktop) {
        width: ((100% / 4) * 1);
    }

    @media screen and (max-width: 400px) {
        width: ((100% / 3) * 1);
    }

    @media screen and (max-width: 300px) {
        width: ((100% / 2) * 1);
    }
}

    .engraving-font--selected {
        .engraving-font__link {
            border: 2px solid $c-brand;
            padding: 0px;
        }
    }

    .engraving-font__link {
        display: block;
        height: 80px;
        border: 1px solid $c-engraving-border-colour;
        background: #fff;
        margin-right: 13px;
        margin-bottom: 13px;
        max-width: 100px;
        // Padding is to counteract the increase in border when selecting
        padding: 1px;
    }

    .engraving-font__image {
        margin-bottom: 5px;
    }

    .engraving-font__name {
        @include webfont(title);
        @include rem(8px);
        color: #999;
        text-align: center;
    }

.engraving-motifs {
    @include remove-white-space;
    margin-right: -13px;
}

.engraving-motif {
    width: ((100% / 4) * 1);

    @include breakpoint(engraving-desktop) {
        width: ((100% / 5) * 1);
    }

    @media screen and (max-width: 400px) {
        width: ((100% / 4) * 1);
    }

    @media screen and (max-width: 300px) {
        width: ((100% / 3) * 1);
    }
}

    .engraving-motif--selected {
        .engraving-motif__link {
            border: 2px solid $c-brand;
            padding: 0px;
        }
    }

    .engraving-motif__link {
        display: block;
        border: 1px solid $c-engraving-border-colour;
        background: #fff;
        margin-right: 13px;
        margin-bottom: 13px;
        max-width: 100px;
        // Padding is to counteract the increase in border when selecting
        padding: 1px;
    }

.engraving-step-footer {
    @include breakpoint(engraving-desktop) {
        display: block;
        background: #fff;
        padding: 15px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: 1px solid $c-engraving-grey;
    }
}

.engraving-step-options-nav {
    @include remove-white-space;
    border: 1px solid $c-engraving-grey;
    display: none;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 7px;

    @include breakpoint(engraving-desktop) {
        display: block;
    }
}

    .engraving-step-options-nav__item {
        $s-border-radius: 7px;
        @include webfont(title);
        @include rem(10px);
        font-weight: bold;
        margin: 0;
        padding: 12px 0;
        display: inline-block;
        text-align: center;
        border-left: 1px solid $c-engraving-grey;

        &:first-child {
            border-left: none;
            border-top-left-radius: $s-border-radius;
            border-bottom-left-radius: $s-border-radius;
        }

        &:last-child {
            border-top-right-radius: $s-border-radius;
            border-bottom-right-radius: $s-border-radius;
            float: right;
        }
    }

    .engraving-step-options-nav__item--selected {
        background: $c-brand;
        color: #fff;
    }

    .engraving-step-options-nav--double .engraving-step-options-nav__item {
        width: 50%;
    }

    .engraving-step-options-nav--triple .engraving-step-options-nav__item {
        width: 33%;

        &:last-child {
            width: 34%;
        }
    }

.engraving-step-nav {}

    .engraving-step-nav__item {
        width: 100%;
    }

    .engraving-step-nav--double {
        .engraving-step-nav__item {
            width: 48%;

            &:first-child {
                margin-right: 4%;
            }
        }
    }

    .engraving-step-nav--triple {
        .engraving-step-nav__item:first-child,
        .engraving-step-nav__item:nth-child(2) {
            width: 48%;
            margin-bottom: 10px;
        }

        .engraving-step-nav__item:first-child {
            margin-right: 4%;
        }
    }

    .engraving-step-nav__item {
        @include webfont(title);
        @include rem(11px);
        @include tracking($t-standard-tracking);
        display: inline-block;
        text-align: center;
        padding: 12px 20px;
    }

.engraving-editor__choices {
}

.engraving-editor__choices--flush {
    @include flush-last-child;
}

.engraving-engraver .engraving-choice {
    position: relative;
    display: block;
    border: 1px solid $c-engraving-border-colour;
    background-color: #fff;
    height: 102px;
    margin: 0 0 10px;
    padding: 20px 10px 0 105px;

    @include breakpoint(engraving-desktop) {
        display: block;
        padding-right: 20px;
    }
}

    .engraving-choice--disabled {
        opacity: .5;
        cursor: default;
    }

    .engraving-choice__thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100px;
    }

    .engraving-engraver .engraving-choice__title {
        @include webfont(title);
        @include rem(13px);
        margin: 0 0 5px;
        font-weight: bold;
    }

    .engraving-choice__description {
        @include rem(14px);
        color: #7D7D7D;

        @media screen and (max-width: 370px) {
            @include rem(12px);
        }
    }

.engraving-step-option {
    margin-bottom: 20px;

    @include breakpoint(engraving-desktop) {
        display: none;
    }
}

    .engraving-step-option--selected {
        display: block;
    }

    .engraving-step-option__label {
        @include webfont(label);
        @include rem(10px);
    }

    .engraving-step-option__textarea {
        border: 1px solid $c-engraving-border-colour;
        padding: 15px;
        outline: none;
        height: 100px;
        min-height: auto;
        resize: none;
    }

    .engraving-step-option__slider {
        margin: 0 8px;
    }

    .engraving-step-option__hint {
        @include rem(12px);
        color: $c-light-bodycopy;
        font-style: italic;
        padding: 10px 0;
        margin-bottom: 0;
    }

.engraving-summary {
    margin-bottom: 20px;
}

    .engraving-summary__title {
        @include webfont(title);
        @include rem(13px);
    }

    .engraving-summary__item {
        @include rem(12px);
        margin-bottom: 5px;
    }

.engraving-content {
    @include flush-last-child;
    padding: 15px;

    @include breakpoint(engraving-desktop) {
        border-bottom: 1px solid $c-engraving-grey;
    }
}
    .engraving-content--center {
        text-align: center;
    }

    .engraving-content__title {
        @include webfont(title);
        @include rem(15px);
        margin-bottom: 20px;
        text-align: center;

        @include breakpoint(engraving-desktop) {
            @include rem(12px);
            margin-bottom: 10px;
            text-align: left;
        }
    }

    .engraving-content__text {
        @include rem(15px);
        margin-bottom: 15px;
        color: #7D7D7D;

        @include breakpoint(engraving-desktop) {
            @include rem(13px);
        }

        a {
            color: $c-secondary-brand;
            text-decoration: underline;
        }
    }

    .engraving-content--doodle-guidelines {
        border-bottom: 1px solid black;

        &:last-child {
            border-bottom: none;
        }
    }

    .engraving-content--doodle-guidelines .engraving-content__title {
        @include rem(12px);
        text-align: left;
        margin-bottom: 10px;
    }

    .engraving-content--doodle-guidelines .engraving-content__text {
        @include rem(12px);
    }

.engraving-buttons {
    .button {
        margin: 10px;

        @media screen and (max-width: 460px) {
            display: block;
            margin: 15px 0;
        }
    }
}

.engraving-error {
    background: #fff;
    border: 1px solid #C44242;
    padding: 10px;
}

    .engraving-error__icon {
        margin-right: 10px;
    }

    .engraving-error__title {
        @include webfont(title);
        @include rem(12px);
        margin-bottom: 10px;
        color: #C44242;
    }

    .engraving-error__message {
        @include rem(13px);
        margin-bottom: 0;
        color: #999;
    }

    .engraving-editor__preview .engraving-error {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        border: 0;
        opacity: 0.9;
    }

    .engraving-editor__preview .engraving-error__title {
        margin-bottom: 0;
    }

    .engraving-editor__step .engraving-error {
        margin-bottom: 10px;
    }

.engraving-finished {}

    .engraving-finished__line {
        display: block;
        margin-bottom: 5px;
        @include rem(13px);
    }

        .engraving-finished__line--bold {
            font-weight: bold;
        }

    .engraving-finished > .button {
        margin-top: 20px;
    }

.engraving-social-mobile {
    display: none;
    clear: both;
    position: relative;

    @include breakpoint(engraving-desktop) {
        display: block;
    }
}

    .engraving-social-mobile__item {
        text-align: center;

        > a {
            border-top: 1px solid grey;
            padding: 15px 0;
            height: 50px;
            width: 100%;
            display: block;
        }

        .social-icon-list {
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 50px;
            text-align: center;
        }

        .social-icon-list__item {
            display: block;
            padding: 10px 0;
            border-top: 1px solid grey;
            background: white;
            height: 50px;
        }
    }

    .engraving-social-mobile__share {
        border-right: 1px solid grey;
    }

.keyboardInputInitiator {
    width: auto;
    float: right;
    margin-top: 5px;
}

.engraving-messages {}

    .engraving-messages__message {
        padding: 10px;
        color: #1a5e1c;
        background: #e5f2de;
        position: relative;
        margin-bottom: 10px;
        @include flush-last-child;
        p {
            margin: 0;
        }
    }

    .engraving-messages__message--error {
        color: #a9251c;
        background: #f7dddb;
    }

    .engraving-messages__message-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }

.engraving-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 10px;
    left: 0;
    background: rgba(white, 0.8);
    z-index: 1000;
}

    .engraving-spinner__container {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -100px;
        width: 200px;
        text-align: center;
    }

    .engraving-spinner__text {
        @include webfont(title);
        @include rem(15px);
    }

    .engraving-spinner__spinner {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 0 auto 20px;
    }

    .engraving-spinner__circle {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .engraving-spinner__circle:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #333;
        border-radius: 100%;
        animation: engraving-spinner-circle-bounce-delay 1.2s infinite ease-in-out both;
    }

    .engraving-spinner__circle--circle2 {
        transform: rotate(30deg);
    }

    .engraving-spinner__circle--circle3 {
        transform: rotate(60deg);
    }

    .engraving-spinner__circle--circle4 {
        transform: rotate(90deg);
    }

    .engraving-spinner__circle--circle5 {
        transform: rotate(120deg);
    }

    .engraving-spinner__circle--circle6 {
        transform: rotate(150deg);
    }

    .engraving-spinner__circle--circle7 {
        transform: rotate(180deg);
    }

    .engraving-spinner__circle--circle8 {
        transform: rotate(210deg);
    }

    .engraving-spinner__circle--circle9 {
        transform: rotate(240deg);
    }

    .engraving-spinner__circle--circle10 {
        transform: rotate(270deg);
    }

    .engraving-spinner__circle--circle11 {
        transform: rotate(300deg);
    }

    .engraving-spinner__circle--circle12 {
        transform: rotate(330deg);
    }

    .engraving-spinner__circle--circle2:before {
        animation-delay: -1.1s;
    }

    .engraving-spinner__circle--circle3:before {
        animation-delay: -1s;
    }

    .engraving-spinner__circle--circle4:before {
        animation-delay: -0.9s;
    }

    .engraving-spinner__circle--circle5:before {
        animation-delay: -0.8s;
    }

    .engraving-spinner__circle--circle6:before {
        animation-delay: -0.7s;
    }

    .engraving-spinner__circle--circle7:before {
        animation-delay: -0.6s;
    }

    .engraving-spinner__circle--circle8:before {
        animation-delay: -0.5s;
    }

    .engraving-spinner__circle--circle9:before {
        animation-delay: -0.4s;
    }

    .engraving-spinner__circle--circle10:before {
        animation-delay: -0.3s;
    }

    .engraving-spinner__circle--circle11:before {
        animation-delay: -0.2s;
    }

    .engraving-spinner__circle--circle12:before {
        animation-delay: -0.1s;
    }

    @-webkit-keyframes engraving-spinner-circle-bounce-delay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
      } 40% {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
    }

    @keyframes engraving-spinner-circle-bounce-delay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
      } 40% {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
    }
