// Webfonts
@mixin webfont($webfont-type: title) {

    // Title
    @if $webfont-type == title {
        display: block;
        //font-family: $webfont-title;
        @include tracking($t-spaced-tracking);
        text-transform: uppercase;
        line-height: $t-tight-lineheight;
    }

    // Subtitle
    @else if $webfont-type == subtitle {
        display: block;
        //font-family: $webfont-bodycopy;
        @include tracking($t-spaced-tracking);
        text-transform: uppercase;
        line-height: $t-tight-lineheight;
    }

    // Bodycopy (probably not required anymore unless resetting another title-style)
    @else if $webfont-type == bodycopy {
        @include tracking(normal);
        //font-family: $webfont-bodycopy;
        line-height: $t-standard-lineheight;
    }

    // Form labels
    @else if $webfont-type == label {
        @include tracking($t-spaced-tracking);
        display: inline-block;
        text-transform: uppercase;
        line-height: $t-tight-lineheight;
        &:hover,
        &:focus {
            cursor: pointer;
        }
    }

    // Undefined
    @else {
        @warn "Unexpected value in webfont mixin";
    }

}
